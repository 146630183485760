<template>
  <div class="bg" id="bg">
    <div class="box">
      <div class="explain">
        刷新页面即可随机群友怪话！
      </div>
    </div>
    <div class="box">
      <div class="title"></div>
    </div>

    <input class="btn" type="button" value="刷新" @click="fetchImages()" />

    <div class="images">
      <img :src="selectedImage" />
      <ul>
        <li v-for="image in selectedImages" :key="image">
          <img :src="image">
        </li>
      </ul>
    </div>

    <div class="author">
      <ul>
        <li v-for="item in imageCounts" :key="item[0]" @click="fetchImages(item[0])">
          {{ item[0] }}: {{ item[1] }}
        </li>
      </ul>
    </div>


  </div>

  <div class="drop-area" @dragover.prevent @drop="onDrop">
    拖拽图片至此即可上传<br> 
    先重命名指定一下作者，上传之后会自动计算hash避免冲突<br>
    示例：心若.png => 心若-7377974351991850983.png
  </div>
</template>

<style>
img {
  width: 300px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  padding: 50px;
  text-align: center;
  font-size: 20px;
  color: #ccc;
  background-color: #f9f9f9;
  transition: .2s;
}

.drop-area:hover {
  border-color: #b2b2b2;
  color: #b2b2b2;
}

ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: space-around;
}

li {
  flex: 1 0 20%;
  margin: 5px;
  list-style-type: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
}

li:hover {
  background-color: #e9e9e9;
}
</style>

<script>
import axios from 'axios';

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      selectedImages: [],
      imageCounts: []
    };
  },
  mounted() {
    this.fetchImages();

    axios.get('https://api.guanceshu.com/image-counts')
      .then(response => {
        this.imageCounts = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    async fetchImages(name = "") {
      try {
        let url = 'https://api.guanceshu.com/images';
        if (name) {
          url += '?name=' + name;
        }
        const response = await axios.get(url);
        this.selectedImages = response.data["data"]
        console.log(this.selectedImages)
      } catch (error) {
        console.error(error);
      }
    },
    getImgUrl: function (image) {
      return require("" + `${image}`);
    },
    onDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.checkFiles(files);
    },
    async checkFiles(files) {
      const formData = new FormData();
      formData.append('file', files[0]);
      const response = await axios.post('https://api.guanceshu.com/upload', formData);
      console.log(response.data["message"]);
      alert(response.data["message"]);
    }
  }
}

</script>